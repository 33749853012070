import React, {useState} from 'react'
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";
import './assets/scss/style.css';
import Home from './containers/home/home';
import FirstGame from "./containers/first-game/first-game";
import data from './data/GameData';
import audioQuestion from "./assets/audio/DramaticDocumentary.mp3";
import Audio from "./containers/audio/audio";

function App() {

    const [isGameLaunched, setGameLaunch] = useState(false);

    return (
    <div>
        {isGameLaunched ? <Audio url={audioQuestion} loop={true}/> : ''}
        <div className="App">
            <div className="paysage">
                <Router>
                    <Route exact path="/">
                        <Home gameLaunched={() => setGameLaunch(true)}/>
                    </Route>
                    <Route exact path="/firstgame">
                        <FirstGame
                            data={data.first}
                            isGameLaunched={isGameLaunched}
                            gameLaunched={() => setGameLaunch(true)}
                            gameEnded={() => setGameLaunch(false)}/>
                    </Route>
                    <Route exact path="/secondgame">
                        <FirstGame
                            data={data.second}
                            isGameLaunched={isGameLaunched}
                            gameLaunched={() => setGameLaunch(true)}
                            gameEnded={() => setGameLaunch(false)}/>
                    </Route>
                    <Route exact path="/thirdgame">
                        <FirstGame
                            data={data.third}
                            isGameLaunched={isGameLaunched}
                            gameLaunched={() => setGameLaunch(true)}
                            gameEnded={() => setGameLaunch(false)}/>
                    </Route>
                </Router>
            </div>
        </div>
    </div>
  );
}

export default App;
