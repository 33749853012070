let GameData;
// eslint-disable-next-line no-unused-vars
export default GameData = {
    first: [
        {
            id: 0,
            questionBold: 'Pour rebondir à court terme,',
            questionNormal: 'il vaut mieux...',
            answerOptions: [
                {answerText: 'Lancer de nouvelles offres', isCorrect: false},
                {answerText: 'Proposer une offre promotionnelle', isCorrect: true}
            ],
            help: 'Lancer une nouvelle offre est une bonne idée mais cela nécessite de mobiliser des moyens humains et\n' +
                'financiers importants',
            helpBold: 'alors qu’une offre promotionnelle va séduire rapidement de nouveaux clients et\n' +
                'remplir rapidement les salles.',
            help2: '',
            image: "G1/Q1-min",
            video: "/video/G1/Q1.mp4",
            videoMobile: "/video/G1/Q1-mobile.mp4",
            link: "https://releve-le-challenge.ecoles-idrac.com/monte-ta-boite/"
        },
        {
            id: 1,
            questionBold: 'Comment conserver l’ensemble des effectifs ?',
            questionNormal: '',
            answerOptions: [
                {answerText: 'Profiter des aides de l’Etat pour les former', isCorrect: true},
                {answerText: 'Demander de prendre des congés le temps que les clients reviennent', isCorrect: false}
            ],
            help: 'Proposer aux employés de prendre des congés alors qu’ils sortent juste d’une période d’inactivité semble délicat.\n' +
                'En revanche, les faire',
            helpBold: 'monter en compétence sur l’accueil des clients ou sur le management d’équipe sera\n' +
                'un plus pour l’entreprise.',
            help2: '',
            image: "G1/Q2-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/monte-ta-boite/"
        },
        {
            id: 2,
            questionBold: 'Pour mieux connaître la situation de l’entreprise,',
            questionNormal: 'tu vas te tourner vers...',
            answerOptions: [
                {answerText: 'Le banquier', isCorrect: false},
                {answerText: 'L’expert-comptable', isCorrect: true}
            ],
            help: 'Le banquier connait bien l’entreprise mais uniquement sur sa dimension financière tandis que',
            helpBold: 'l’expert-comptable est le conseillé de proximité, il a une vision plus globale.',
            help2: 'De plus, il a une relation plus\n' +
                'régulière avec le chef d’entreprise.',
            image: "G1/Q3-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/monte-ta-boite/"
        },
        {
            id: 3,
            questionBold: 'Pour conquérir de nouveaux clients,',
            questionNormal: 'tu penses privilégier...',
            answerOptions: [
                {answerText: 'Un système de parrainage', isCorrect: true},
                {answerText: 'Un cadeau de bienvenue (un t-shirt pour toute nouvelle inscription)', isCorrect: false}
            ],
            help: 'Le T-shirt pourquoi pas ! Cependant,',
            helpBold: 'le parrainage est plus efficace car il permet de valoriser l’expérience\n' +
                'client.',
            help2: 'On fait plus facilement confiance à quelqu’un que l’on connait.',
            image: "G1/Q4-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/monte-ta-boite/"
        }
    ],
    second: [
        {
            id: 0,
            questionBold: 'Pour créer ton entreprise,',
            questionNormal: 'tu dois commencer par réfléchir à ton...',
            answerOptions : [
                { answerText: 'Business model', isCorrect: true },
                { answerText: 'Business plan', isCorrect: false }
            ],
            help: 'Le Business plan est indispensable pour convaincre, notamment ton banquier, mais tu ne peux pas le réaliser\n' +
                'sans avoir au préalable',
            helpBold: 'défini ton Business model. Le Business model correspond à l’organisation que tu\n' +
                'souhaites mettre en place et à la manière dont tu penses faire des bénéfices.',
            help2: '',
            image: "G2/Q1-min",
            video: "/video/G2/Q1.mp4",
            videoMobile: "/video/G2/Q1-mobile.mp4",
            link: "https://releve-le-challenge.ecoles-idrac.com/sauve-entreprise/"
        },
        {
            id: 1,
            questionBold: 'Fonder son entreprise',
            questionNormal: 'c’est avant tout une question...',
            answerOptions : [
                { answerText: 'D’argent', isCorrect: false },
                { answerText: 'D’idée', isCorrect: true }
            ],
            help: 'L’argent est important mais',
            helpBold: 'l’idée est essentielle. Si votre idée est bonne et que votre business model est\n' +
                'crédible, il existe de nombreuses formules pour faciliter la création d’entreprise et son lancement.',
            help2: 'L’Etat\n' +
                'français n’a jamais autant soutenu l’entrepreneuriat et notre école participe à cet élan entrepreneurial via de\n' +
                'nombreuses initiatives (incubateur, référent entrepreneuriat, etc.). D’ailleurs, il est possible d’être étudiant et\n' +
                'entrepreneur à la fois.',
            image: "G2/Q2-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/sauve-entreprise/"

        },
        {
            id: 2,
            questionBold: 'Une fois ton entreprise créée,',
            questionNormal: 'le plus important est de...',
            answerOptions : [
                { answerText: 'Surveiller ta trésorerie', isCorrect: true },
                { answerText: 'Regarder le nombre de clients', isCorrect: false }
            ],
            help: 'Le nombre de clients est essentiel mais',
            helpBold: 'la trésorerie reste fondamentale.',
            help2: 'Deux tiers des entreprises\n' +
                'disparaissent la 1 ère année suite à des problèmes de trésorerie alors qu’elles ont des clients.',
            image: "G2/Q3-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/sauve-entreprise/"
        },
        {
            id: 3,
            questionBold: 'A la tête de ta jeune entreprise,',
            questionNormal: 'quelle est l’échéance la plus délicate ?',
            answerOptions : [
                { answerText: 'La 1ère année', isCorrect: false },
                { answerText: 'La 3ème année', isCorrect: true }
            ],
            help: 'La 1 ère année se passe souvent plutôt bien car les objectifs du chiffre d’affaires sont relativement faibles.',
            helpBold: 'Au\n' +
                'bout de 3 ans si ton entreprise n’est pas toujours rentable, elle risque de disparaitre. Une fois le cap des\n' +
                '3 ans passé, ton entreprise a 9 chances sur 10 de continuer cette aventure.',
            help2: '',
            image: "G2/Q4-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/sauve-entreprise/"
        },
    ],
    third: [
        {
            id: 0,
            questionBold: 'D’après toi,',
            questionNormal: 'quel est le meilleur outil de visibilité pour la marque ?',
            answerOptions : [
                { answerText: 'Les réseaux sociaux ', isCorrect: false },
                { answerText: 'Le référencement de ton site web', isCorrect: true }
            ],
            help: '',
            helpBold: 'Le référencement Google est la base de toute bonne stratégie digitale.',
            help2: 'Les réseaux sociaux sont davantage\n' +
                'destinés à animer une communauté.',
            image: "G3/Q1-min",
            video: "/video/G3/Q1.mp4",
            videoMobile: "/video/G3/Q1-mobile.mp4",
            link: "https://releve-le-challenge.ecoles-idrac.com/strategie-marketing-digital/"
        },
        {
            id: 1,
            questionBold: 'Pour le lancement de cette marque,',
            questionNormal: 'quel réseau social conseilles-tu ?',
            answerOptions : [
                { answerText: 'Instagram', isCorrect: true },
                { answerText: 'LinkedIn', isCorrect: false }
            ],
            help: 'LinkedIn est un excellent réseau social mail il est surtout considéré comme dédié à l’univers Business.',
            helpBold: 'Instagram est devenu en quelques années le réseau social de référence du secteur de la mode avec de\n' +
                'nombreux influenceurs.',
            help2: 'Toutes les grandes marques, ainsi que la presse spécialisée, sont présentes sur\n' +
                'Instagram.',
            image: "G3/Q2-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/strategie-marketing-digital/"
        },
        {
            id: 2,
            questionBold: 'Quels sont les critères',
            questionNormal: 'de sélection d’un bon influenceur ?',
            answerOptions : [
                { answerText: 'Bien connaître la marque et être fan de montres', isCorrect: false },
                { answerText: 'Disposer d’une forte notoriété sur les réseaux sociaux', isCorrect: true }
            ],
            help: 'C’est sympa que l’influenceur connaisse bien la marque, mais dans le cadre d’une opération de',
            helpBold: 'communication\n' +
                'il est indispensable que ce dernier ait une belle renommée sur les réseaux sociaux et soit suivi par un\n' +
                'grand nombre de followers.',
            help2: '',
            image: "G3/Q3-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/strategie-marketing-digital/"
        },
        {
            id: 3,
            questionBold: ' Ta montre va être disponible sur le site e-commerce de la marque',
            questionNormal: 'Tu vas devoir surveiller ',
            answerOptions : [
                { answerText: 'Le nombre de visite  ', isCorrect: false },
                { answerText: 'Le nombre de conversion', isCorrect: true }
            ],
            help: 'Quand il est question d’un produit de luxe ,',
            helpBold: 'c’est le nombre de conversion qui va être important, c’est-à-dire\n' +
                'le nombre de personne qui achète .',
            help2: 'Le nombre de visite est souvent flatteur mais il peut être trompeur\n' +
                '(exemple : visites de courtoisie pour découvrir les nouveautés).',
            image: "G3/Q4-min",
            video: "",
            videoMobile: "",
            link: "https://releve-le-challenge.ecoles-idrac.com/strategie-marketing-digital/"
        },
    ]

};
