import React, {useEffect, useState} from 'react';
import {
    Link
} from "react-router-dom";

import Background from '../../assets/video/choix-mission.mp4';

import mobileBackground from '../../assets/images/visuel-home/choix-mission-mobile.jpg';
import image1 from '../../assets/images/visuel-home/visuel-home-zoom-min.jpg';
import image2 from '../../assets/images/visuel-home/visuel-home-zoom-2-min.jpg';
import image3 from '../../assets/images/visuel-home/visuel-home-zoom-3-min.jpg';

function Home(props) {
    const {gameLaunched} = props;
    const [reload, setReload] = useState(false);

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    return (
        <section className="home">
            {window.matchMedia("(orientation: portrait)").matches
                ?
                <div>
                    {reload ? <img src={mobileBackground} alt="background" className="choix__backgroundMobile" /> :
                        <img rel="preload" src={mobileBackground} alt="background" onError={() => setReload(true)} className="choix__backgroundMobile" />}
                    <h2 className="choix__mobile-titre">CHOISIS <strong>TA MISSION</strong></h2>
                </div>
                :
                <video
                    playsInline={true}
                    loop={true}
                    src={Background}
                    muted={true}
                    autoPlay={true}
                    controls={false}
                    rel="preload"
                    preload="true"
                />
            }
            <div className="items">
                <Link className="choix" to="/firstgame" onClick={() => gameLaunched()}>
                    <img rel="preload" className="choix__image" src={image1} alt="illustration" />
                    <h2 className="choix__titre choix__titre--1">Sauve l'entreprise</h2>
                </Link>
                <Link className="choix" to="/secondgame" onClick={() => gameLaunched()}>
                    <img rel="preload" className="choix__image" src={image2} alt="illustration"/>
                    <h2 className="choix__titre choix__titre--2">Monte ta boîte</h2>
                </Link>
                <Link className="choix" to="/thirdgame" onClick={() => gameLaunched()}>
                    <img rel="preload" className="choix__image" src={image3} alt="illustration"/>
                    <h2 className="choix__titre choix__titre--3">Trouve la stratégie <br/> marketing digital</h2>
                </Link>
            </div>
        </section>
    );
}

export default Home;
