import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import React, {useState} from 'react';

export default function Example() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button className="end-btn-ml" variant="primary" onClick={handleShow}>
                Mentions légales
            </Button>

            <Modal className="modal-perso" show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Mentions Légales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mentions-content">
                        Conformément aux dispositions des articles 6-III et 19 de la loi pour la Confiance dans l’Economie Numérique, nous vous informons que : <br/>
                        <br/>
                        Co-éditeurs :<br/>
                        <br/>
                        AIPF – Association Internationale Pour la Formation, Association régie par la Loi de 1901, déclarée en Préfecture sous le numéro W691055255, publiée au JO le 29 avril 1984, immatriculée au répertoire SIREN sous le numéro 330 377 524, dont le siège social est situé 47 rue du Sergent Michel Berthet, 69009 LYON.<br/>
                        <br/>
                        Compétences Commerce et International (ancienne dénomination IDRAC), société à responsabilité limitée au capital de 127 200 euros, dont le siège social est situé au 20 bis jardins Boieldieu – Paris la Défense 8 – 92800 PUTEAUX, Immatriculée au registre du commerce et des sociétés sous le numéro 420 720 567 RCS NANTERRE.<br/>
                        <br/>
                        SIRET : 420720567 00189<br/>
                        <br/>
                        Numéros de TVA intracommunautaire :<br/>
                        – AIPF : FR 67 330 337 524<br/>
                        – Compétences Commerce et International (ancienne dénomination IDRAC) : FR 41 420 720 567<br/>
                        <br/>
                        Le directeur de publication du Site est Laurent ESPINE, directeur de la marque IDRAC Business School. Pour le contacter, vous pouvez lui adresser un courrier à IDRAC Business School, 47, rue Sergent Michel Berthet CP 607 69258 Lyon cedex 09, ou encore lui adresser un email à webmaster(@)ecoles-idrac.com<br/>
                        <br/>
                        Le responsable éditorial du Site est M. Laurent ESPINE, directeur IDRAC Business School. Pour le contacter, vous pouvez lui adresser un courrier à IDRAC Business School - Service Communication Digitale 499 rue de la Croix Verte Parc Euromédecine 34196 MONTPELLIER Cedex 5, ou encore lui adresser un email à webmaster(@)ecoles-idrac.com<br/>
                        <br/>
                        Hébergement :<br/>
                        <br/>
                        Ce site est hébergé sur les serveurs de la société OVH *www.ovh.com* :<br/>
                        OVH - Siège social : 2 rue Kellermann - 59100 Roubaix - France<br/>
                        <br/>
                        Tél : 1007* :<br/>
                        Tél : +33 9 72 10 10 07**<br/>
                        <br/>
                        *numéro unique gratuit<br/>
                        gratuit depuis un poste fixe, hors surcoût éventuel selon opérateur depuis une ligne mobile.<br/>
                        <br/>
                        **Prix d'un appel vers un poste fixe en France<br/>
                        <br/>
                        La programmation du site a été réalisée par l’Agence i3a Communication, basée à LYON (France).
                        <br/>
                        Le site est référencé dans les moteurs de recherche par l’Agence web Vu du web.
                        <br/>
                        Crédits photos : Freepik / Pexels<br/>
                        <br/>
                        L’accès et l’Utilisation du site impliquent l’acceptation sans réserve des conditions suivantes :<br/>
                        I. Propriété Intellectuelle<br/>
                        II. Garanties<br/>
                        III. Protection des Données Personnelles et Cookies<br/>
                        <br/>
                        <h2>I. Propriété Intellectuelle</h2><br/>
                        Toutes les données de quelque nature qu’elles soient, et notamment les textes, graphismes, logos, icônes, images, clips audio ou vidéo, marques, logiciels, figurant sur le site, sont nécessairement protégées par le droit d’auteur, le droit des marques et tous autres droits de propriété intellectuelle. L’IDRAC Business School, en sa qualité d’éditeur du site, consent à l’utilisateur un droit d’usage privé, non collectif et non exclusif sur ledit contenu. L’utilisateur s’engage dans ces conditions à ne pas reproduire, résumer, modifier, altérer ou rediffuser, sans autorisation expresse préalable de l’éditeur, quelque texte, titre, application, logiciel, logo, marque, information ou illustration, pour un usage autre que strictement privé, ce qui exclut toute représentation à des fins professionnelles ou de rediffusion en nombre. De même, l’utilisateur s’engage à ne pas recopier tout ou partie du site sur un autre site ou un réseau interne d’entreprise. L’IDRAC ne saurait être tenu responsable des utilisations faites par les Utilisateurs des données accessibles sur le Site. Toute création de lien hypertexte d’un site Internet vers le site devra faire l’objet d’une autorisation expresse de l’IDRAC.
                        <br/>
                        <h2>II. Garanties</h2><br/>
                        L’IDRAC Business School s’efforce d’offrir aux utilisateurs l’information la plus fiable et qualitative qui soit. L’IDRAC Business School ne garantit pas cependant l’exactitude et l’exhaustivité de l’information diffusée sur le site. L’IDRAC Business School ne saurait donc être tenue pour responsable des dommages directs ou indirects, subis par les Utilisateurs ou par des tiers qui trouveraient leur source dans l’information diffusée sur le Site, et de manière plus générale, dans la consultation et l’utilisation du site. De même, l’IDRAC Business School n’exerce aucune surveillance sur les sites reliés au site par le biais de liens hypertexte, et décline toute responsabilité liée à ces sites et aux contenus qui s’y trouvent.
                        <br/>
                        <h2>III.Cookies</h2><br/>
                        <br/>
                        En consultant ce Site Internet, vous êtes exposé au dépôt de cookies ou de traceurs sur votre terminal, que ce soit directement (par le site lui-même) ou indirectement (par des services tiers). Ce dépôt permet le stockage d’informations sur votre terminal ou l’accès à des informations déjà stockées, pour garantir un fonctionnement optimal du site ou suivre vos activités sur Internet.
                        <br/>
                        En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour garantir un fonctionnement optimal du site, réaliser des statistiques de visites et vous proposer des offres ciblées, adaptés à vos centres d’intérêts.
                        <br/>
                        En savoir plus sur les cookies, leur fonctionnement et les moyens de s’y opposer
                        <br/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
