import React, {useState, useEffect} from 'react';
import Background from '../../assets/images/main.jpg';
import audioChrono from '../../assets/audio/chrono.mp3';
import Lottie from 'react-lottie';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import animationData1 from '../../lotties/stars-anim.json';
import animationData2 from '../../lotties/minuteur.json';
import videoScoreMobile from '../../assets/video/score-mobile.mp4';
import videoScore from '../../assets/video/score.mp4';
import starsAudio from '../../assets/audio/etoile_son.mp3';
import {Link} from "react-router-dom";
import Mentions from '../../components/modal';
import Audio from '../audio/audio';
import {isDesktop} from 'react-device-detect';

export default function App(props) {

    const renderTime = ({ remainingTime }) => {
        setTimeout(() => {
            if (!showVideo && !stopCounter && !timeEnded) {
                setTimeClock(remainingTime);
            }
        })
    };

    const starsOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData1,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const minuteurOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: ""
        }
    };
    const questions = props.data;
    const {isGameLaunched, gameLaunched, gameEnded} = props;

    // useEffect(() => {
    //     // if (!isGameLaunched && !isIOS) gameLaunched();
    // }, [isGameLaunched, gameLaunched]);

    useEffect(() => {
        const isReloaded = sessionStorage.getItem('trickIOS');
        if (!isReloaded) {
            sessionStorage.setItem('trickIOS', 'true');
            if (!isDesktop) {
                window.location.reload(true);
            }
        }
    }, []);

    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [showVideo, setShowVideo] = useState(true);
    const [score, setScore] = useState(0);
    const [shadow, setShadow] = useState(true);
    const [isGood, setIsGood] = useState(false);
    const [needHelp, setNeedHelp] = useState(false);
    const [timeEnded, setTimeEnded] = useState(false);
    const [stopCounter, setStopCounter] = useState(false);
    const [timeClock, setTimeClock] = useState(15);
    const [displayStartButton, setDisplayStartButton] = useState(false);

    useEffect(() => {
        if (showResult) {
            sessionStorage.clear();
        }
    }, [showResult]);

    useEffect(() => {
        if (timeClock === 0) setTimeEnded(true);
    }, [setTimeEnded, timeClock]);

    const handleAnswerOptionClick = (isCorrect) => {
        setStopCounter(true);
        if (isCorrect) {
            setScore(score + 1);
            setIsGood(true);
            setTimeout(() => {
                setShadow(false);
            }, 2000);
        }
        if(!isCorrect) {
            setStopCounter(false);
            setNeedHelp(true);
            setShowVideo(false);
        }
    };

    useEffect(() => {
        if (stopCounter && isGood && !shadow) {
            const nextQuestion = currentQuestion + 1;
            setStopCounter(false);
            if (nextQuestion < questions.length) {
                setCurrentQuestion(nextQuestion);
                setIsGood(false);
            } else {
                setShowScore(true);
            }
            setShadow(true);
        }
    }, [setStopCounter, setCurrentQuestion, setIsGood, setShadow, currentQuestion, isGood, questions, shadow, stopCounter])

    const endVideo = () => {
        if (showVideo) setShowVideo(false);
        if (gameLaunched) gameLaunched();
    };

    const autoEndVideo = () => {
        if (!window.matchMedia("(orientation: portrait)").matches) {
            endVideo();
        }
    };

    const introductionVideoStarted = () => {
        setTimeout(() => {
            if (!displayStartButton) setDisplayStartButton(true);
        }, 500);
    }

    const presque = (question) => {
        setTimeEnded(false)
        setNeedHelp(false);
        setCurrentQuestion(question);
        setIsGood(false);
    };

    const good = {
        color: 'white',
        backgroundColor: 'green',
        animation: '2s'
    }
    const notgood = {

    }

    const divStyle = {
        position: 'fixed',
        zIndex: 9999,
        width: '10em',
        right: 'calc(50% - 5em)',
        bottom: '10%',
        fontSize: '1.2em',
        textTransform: 'uppercase',
        backgroundColor: 'darkred',
        color: 'white',
        padding: '5px 15px',
        borderRadius: '50px',
        border: 'darkred solid 2px',
        outline: 'none',
        cursor: 'pointer'
    }


    return (
        <div className='app'>
            {needHelp || timeEnded
                ?
                (<div className="presque">
                    <img src={Background} alt="travail"/>
                    <div className="shadow"/>
                    <div className="presque-content">
                        {timeEnded
                            ?
                            <>
                                <h2 className="presque-title">Tu n’as pas eu le temps de répondre à la question…
                                    <br/>vite, tente de nouveau ta chance !</h2>
                                <button className="presque-btn" onClick={() => presque(questions[currentQuestion].id)}>Continuer</button>
                            </>
                            :
                            <>
                                <h2 className="presque-title">TU Y ES PRESQUE :</h2>
                                <p className="presque-text">
                                    {questions[currentQuestion].help}
                                    <strong> {questions[currentQuestion].helpBold} </strong>
                                    {questions[currentQuestion].help2}
                                </p>
                                <button className="presque-btn" onClick={() => presque(questions[currentQuestion].id)}>Continuer</button>
                            </>
                        }

                    </div>

                </div>)
                :
                (<>
                    {showScore
                        ?
                        (<div className="end">
                            {showResult ? <div>
                                {window.matchMedia("(orientation: portrait)").matches
                                    ? <a className="end-btn" href={questions[currentQuestion].link}>Découvre<br/>ton résultat</a>
                                    : <a className="end-btn" href={questions[currentQuestion].link}>Découvre ton résultat</a>
                                }
                            </div> : <video
                                autoPlay={true}
                                muted={true}
                                playsInline={true}
                                rel="preload"
                                preload="true"
                                loop={false}
                                controls={false}
                                onEnded={() => setShowResult(true)}
                                onError={() => setShowResult(true)}
                            >
                                {window.matchMedia("(orientation: portrait)").matches
                                    ? <source src={videoScoreMobile} type="video/mp4"/>
                                    : <source src={videoScore} type="video/mp4"/>
                                }
                                <p className="vjs-no-js">
                                    To view this video please enable JavaScript, and consider upgrading to a
                                    web browser that
                                    <a href="https://videojs.com/html5-video-support/" rel="noreferrer" target="_blank"
                                    >supports HTML5 video</a>
                                </p>
                            </video>}
                            <Mentions className="end-btn-ml"/>

                        </div>)
                        :
                        (<>
                            {showVideo ? (
                                    <div className='video'>
                                        {displayStartButton ? <button
                                            type="button"
                                            className="startButtonFadeIn"
                                            onClick={() => endVideo()}
                                            style={divStyle}>
                                            C'est parti !
                                        </button> : ''}
                                        <video
                                            autoPlay={true}
                                            muted={true}
                                            playsInline={true}
                                            onEnded={() => autoEndVideo()}
                                            onPlay={() => introductionVideoStarted()}
                                            onError={() => introductionVideoStarted()}
                                            rel="preload"
                                            preload="true"
                                            loop={false}
                                            poster={"images/question"+questions[currentQuestion].image+".jpg"}
                                            controls={false}
                                        >
                                            {window.matchMedia("(orientation: portrait)").matches
                                                ? <source src={questions[currentQuestion].videoMobile} type="video/mp4"/>
                                                : <source src={questions[currentQuestion].video} type="video/mp4"/>
                                            }
                                            <p className="vjs-no-js">
                                                To view this video please enable JavaScript, and consider upgrading to a
                                                web browser that
                                                <a href="https://videojs.com/html5-video-support/" rel="noreferrer" target="_blank"
                                                >supports HTML5 video</a>
                                            </p>
                                        </video>
                                    </div>
                            ) : (
                                <>
                                    <div className="question">
                                        <img rel="preload" src={"images/"+questions[currentQuestion].image+".jpg"} alt="question"/>
                                        <img rel="preload" src={"images/batterie_"+questions[currentQuestion].id+".png"} alt={"step " + currentQuestion + 1} className="question__batterie"/>
                                        {shadow ? <div className="shadow"/> : ''}
                                        <div className="question__content">
                                            <h2 className="question__content__title"><strong>{questions[currentQuestion].questionBold}</strong><br/>{questions[currentQuestion].questionNormal}</h2>
                                            <div className="question__content__answer">
                                                {questions[currentQuestion].answerOptions.map((answerOption, i) => (
                                                    <button style={ answerOption.isCorrect && isGood ? good : notgood} key={i} onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}>
                                                        {answerOption.answerText}
                                                        <div className={ answerOption.isCorrect && isGood ? "anim-stars-good" : "anim-stars-notgood"}>
                                                            <Lottie
                                                                options={starsOptions}
                                                                height={150}
                                                                width={150}
                                                            />
                                                            { answerOption.isCorrect && isGood
                                                                ?
                                                                <Audio url={starsAudio} loop={false}/>
                                                                 : ""}
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                        {stopCounter ? '' : <div>
                                            <div className="question__content__countdown--2">
                                                <Lottie
                                                    options={minuteurOptions}
                                                    height={125}
                                                    width={125}
                                                />
                                            </div>
                                            <div style={{display: "none"}}>
                                                {!timeEnded ? <CountdownCircleTimer
                                                    isPlaying
                                                    duration={15}
                                                    colors={[["#681B2B", 0.33], ["#681B2B", 0.33], ["#681B2B"]]}
                                                >
                                                    {renderTime}
                                                </CountdownCircleTimer> : ''}
                                            </div>
                                            <Audio url={audioChrono} loop={false}/>
                                        </div>}
                                        <Link to="/" className="q-btn" onClick={() => gameEnded()}>Retour au Menu</Link>
                                    </div>
                                </>
                            )}
                        </>)
                    }
                </>)
            }

        </div>
    );
}


