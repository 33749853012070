import ReactPlayer from 'react-player';
import {useState, useEffect} from 'react';
import {isIOS} from 'react-device-detect';

function Audio(props) {
    const {url, loop} = props;
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (isIOS) {
            // setReload(true);
        }
        if (!ReactPlayer.canPlay(url)) {
            setReload(true);
        }
    }, [setReload, url]);

    return (
        <div>
            {reload ?
                <audio
                    rel="preload"
                    preload="true"
                    src={url}
                    controls={false}
                    autoPlay={true}
                    muted={false}
                    loop={loop}
                />
                :
                <ReactPlayer
                    controls={false}
                    playing={true}
                    muted={false}
                    loop={loop}
                    onError={() => setReload(true)}
                    width="0px"
                    height="0px"
                    url={url} />
            }
        </div>
    );
}

export default Audio;
